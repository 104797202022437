import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);
//创建并暴露一个路由器
const routes = [
   {
      path: "/",
      name: "layout",
      component: Layout,
      redirect: "/home/index",
      children: [
         {
            path: "/home/index",
            name: "Home",
            component: () => import("@/views/home/index.vue"),
         },
         {
            path: "/privacyPolicy/index",
            name: "PrivacyPolicy",
            component: () => import("@/views/privacyPolicy/index.vue"),
         },
      ],
   },
];

const router = new VueRouter({
   mode: "history",
   base: "",
   routes,
   scrollBehavior () {
      //函数拥有 to和from参数
      //可以通过 return {x:0,y:0} 来控制滚动条
      //console.log(arguments)
      return { x: 0, y: 0 };
   },
});

export default router;
