<!-- 导航栏 -->

<template>
  <div class="model">
    <div class="container">
      <div class="nav">
        <div class="nav-left">
          <img src="@/assets/logo.png" alt="" />
          <span class="img-text">X-GPT</span>
        </div>
        <div class="nav-right">
          <div
            class="nav_list"
            v-for="(item, index) in navList"
            :key="index"
            @click="goLink(index, item.path)"
            :style="{ fontWeight: indexNum === item.id ? 900 : 400 }"
          >
            <span>
              {{ item.name }}
            </span>
            <div
              style="width: 60%; height: 0.03rem; margin-top: 0.06rem"
              :class="{ line: indexNum == item.id }"
            ></div>
            <!-- visibility:hidden -->
          </div>
        </div>
      </div>

      <div class="contend">
        <transition name="transitionRouter">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexNum: 1,
      navList: [
        {
          id: 1,
          name: "HOME",
          path: "/home/index",
        },
        {
          id: 2,
          name: "PRIVACY POLICY",
          path: "/privacyPolicy/index",
        },
      ],
    };
  },
  methods: {
    goLink(index, path) {
      this.indexNum = index + 1;
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
.model {
  width: 100%;
  margin: 0;
  .container {
    .nav {
      width: 100%;
      height: 0.8rem;
      display: flex;
      align-items: center;
      position: fixed;
      z-index: 99;
      top: 0;
      background: #2663ef;
      .nav-left {
        display: flex;
        align-items: center;
        height: 0.32rem;
        margin-left: 3.6rem;
        img {
          margin-left: 3.6px;
          height: 0.32rem;
          width: 0.32rpx;
        }
        .img-text {
          font-size: 0.28rem;
          font-weight: 600;
          color: #ffffff;
          line-height: 0.42rem;
          margin-left: 0.17rem;
        }
      }
      .nav-right {
        margin-left: 6.8rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .nav_list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 0.62rem;
          font-size: 0.22rem;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
          margin-top: 0.04rem;
          .line {
            background: #ffffff;
            border-radius: 0.02rem;
          }
        }
      }
    }
    .contend {
      position: relative;
    }
  }
}
.link {
  color: #ffffff;
}
</style>
